
  button {
    border: 2px solid;
  }

  span.error {
    width: 100%;
    display: block;
    text-align: left;
    color: red;
  }
  a {
    font-family: 'Philosopher', sans-serif !important;
  }